@keyframes ldio-9yhdryxy226 {
    0% {
        top: 34px;
        left: 34px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: -9px;
        left: -9px;
        width: 86px;
        height: 86px;
        opacity: 0;
    }
}

.ldio-9yhdryxy226 div {
    position: absolute;
    border-width: 16px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ldio-9yhdryxy226 1.5384615384615383s cubic-bezier(0,0.2,0.8,1) infinite;
}

.ldio-9yhdryxy226 div:nth-child(1) {
    border-color: #93dbe9
}

.ldio-9yhdryxy226 div:nth-child(2) {
    border-color: #689cc5;
    animation-delay: -0.7692307692307692s;
}

.loadingio-spinner-ripple-78lphliuwla {
    width: 98px;
    height: 98px;
    display: flex;
    margin: auto;
    overflow: hidden;
    background: none;
}
.ldio-9yhdryxy226 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.98);
    backface-visibility: hidden;
    transform-origin: 0 0;
}
.ldio-9yhdryxy226 div { box-sizing: content-box; }